@tailwind base;
@tailwind components;
@tailwind utilities;

.anticon svg {
  display: block !important;
}

.app-header-box-shadow {
  box-shadow: 0px 4px 12px 0px rgba(82, 108, 109, 0.13);
}

/** 修改浏览器滚动条样式 */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f6f7f9;
}

::-webkit-scrollbar-thumb {
  background-color: #9199a7;
  border-radius: 3px;
}
